/* You can add global styles to this file, and also import other style files */
@import url("https://use.fontawesome.com/releases/v6.2.0/css/all.css");
@import "@angular/cdk/overlay-prebuilt.css";
@import '@angular/cdk/a11y-prebuilt.css';


.user-icon {

}

.pdf-viewer {
  height: calc(100vh - 152px);
}

.box {
  width: 100%;
  border: 2px solid var(--active-primary) !important;
  text-align: center;
  margin-bottom: 10px;
}

.box h2 {
  font-size: 30px;
}

.box h3 {
  font-size: 18px;
}


.apexcharts-yaxis-label {
  cursor: pointer;
}

.footer-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px -5px 24px;
  background: var(--sidebar-background-color) !important;
  color: var(--sidebar-footer-button-color) !important;
  height: 40px !important;
}

.example-container {
  box-sizing: border-box;
  max-height: calc(100vh - 40px);
  min-height: calc(100vh - 40px);
  height: calc(100vh - 40px);
  overflow-y: auto !important;
  padding: 12px;
}

.box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #969696;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #969696;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--interactive-primary);
  border-radius: 20px;
  border: 3px solid var(--interactive-primary);
}

.viewer {
  border: 1px solid var(--toolbar-border-bottom-color);
  width: 100%;
  background: var(--ui-01);
  position: relative;
}

.table-card-viewer {
  width: 100%;
  position: relative;
  background: transparent !important;
  border: none !important;
}

.header {
  border: none !important;
  width: 100%;
  background: transparent !important;
  position: relative;
}

.search-bar {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 200px;
  right: 10px;
  z-index: 1000;
}

.top-hr {
  padding-top: 4px;
  margin-top: 4px;
  border-top: 1px solid var(--interactive-primary);
}

.table-mailto {
  color: var(--link-active-color);
}

.back-left {
  margin-left: auto;
}

.back-right {
  margin-right: auto;
}

.input-prepend {
  font-size: 14px;
  background-color: var(--toolbar-background);
  padding: 6px 3px;
  margin-bottom: -3px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nx-formfield__prefix {
  margin-right: 0px !important;
}

.input-append {
  font-size: 14px;
  background-color: var(--toolbar-background);
  margin-bottom: -3px;
  padding: 3px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-left: 3px;
}

.gray-background {
  background-color: var(--ui-02) !important;
  color: var(--text) !important;
  border-radius: 4px;
  padding: 4px 8px;
}

.table-card-toolbar {
  height: 48px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  color: var(--dark-text);
  font-weight: bold;
}

.bar-as-toolbar {
  height: 48px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  border-bottom: 2px solid var(--toolbar-background);
}

.bar-as-toolbar-small {
  font-size: 14px;
  height: 30px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.contact-body {
  margin-left: 12px;
}

.contact-head {
  font-size: 14px;
  font-weight: bolder
}

.mobile-hading {
  font-size: var(--heading-04-mobile-font-size) !important;
}

.active-view {
  color: var(--active-primary) !important;
}

@media (max-width: 1440px) {
  .aquila-table-custom [class="nx-table-row"] {
    display: flex;
    flex-direction: column;
  }

  .aquila-table-custom [class="nx-table-row"] > td {
    margin-bottom: 1.2rem;
  }

  .aquila-table-custom [class="nx-table-row"] > td.clear-td {
    margin-bottom: 0;
    width: 100%;
  }
}

nx-formfield div.nx-formfield__wrapper {
  padding-bottom: 0;
}

.overlay-on-mobile {
  position: absolute !important;
  z-index: 2;
  padding-bottom: 40px !important;
}

.sidebar-mobile-spacer {
  width: 70px;
}

.translucent-sidebar {
  opacity: 0.95;
}

div.webhook-headline h4 {
  font-size: var(--heading-05-font-size) !important;
  line-height: var(--heading-05-line-height) !important;
  font-weight: var(--heading-05-font-weight) !important;
  letter-spacing: var(--heading-05-letter-spacing) !important;
}

nx-toolbar.justify-content-between.d-flex {
  padding: 0 32px !important;
}

@media (max-width: 991px) {
  nx-toolbar.justify-content-between.d-flex {
    padding: 0 10px !important;
  }
}
