:root,
:host {
  --font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  --ui-background: #EEF1F3;
  --ui-01: #FFFFFF;
  --ui-02: #E8E8E8;
  --ui-03: #D3D3D3;
  --ui-04: #BEBEBE;
  --ui-05: #A9A9A9;
  --ui-06: #808080;
  --interactive-primary: #be20bf;
  --interactive-text: var(--interactive-primary);
  --hover-primary: #841e70;
  --hover-secondary: var(--interactive-primary);
  --active-primary: #be20bf;
  --hover-link: #841e70;

  --focus-box-shadow: 0 0 0 1px var(--active-primary), 0 0 0 3px var(--hover-link);
  --focus-inset-box-shadow: inset 0 0 0 4px var(--active-primary), inset 0 0 0 6px var(--hover-link);

  --dark-text:#2F4050;
  --text: #000;
  --text-01: #676a6c;
  --text-02: #ffffff;
  --info: #23c6c8;
  --warning: #f8ac59;
  --danger: #ED5565;
  --success: #26AE31FF;

  --accent-01: #00b17f;
  --accent-02: #6b8cff;
  --disabled-01: #969696;
  --disabled-02: #dbdbdb;
  --disabled-03: #ededed;
  --disabled-04: rgba(40, 64, 80, 0.35);
  --negative: var(--ui-01);
  --negative-accent: #e5e7e6;
  --negative-01: #23628f;
  --negative-02: #DBF227;
  --negative-background: #e5e7e6;
  --negative-dimmed: rgba(15, 42, 61, 0.4);
  --negative-01-dimmed: var(--negative-01);
  --negative-02-dimmed: var(--negative-02);
  --negative-background-dimmed: rgba(229, 231, 230, 0.4);
  --grid-max-width: 1184px;
  --readonly-01: rgba(150, 150, 150, 0.35);
  --readonly-02: #969696;
  --readonly-03: #ffffff;
  --heading-01-font-size: 44px;
  --heading-01-line-height: 5px;
  --heading-01-font-weight: 300;
  --heading-01-letter-spacing: 0.3px;
  --heading-01-mobile-font-size: var(--heading-02-font-size);
  --heading-01-mobile-line-height: var(--heading-02-line-height);
  --heading-01-mobile-font-weight: var(--heading-02-font-weight);
  --heading-01-mobile-letter-spacing: var(--heading-02-letter-spacing);
  --heading-02-font-size: 30px;
  --heading-02-line-height: 38px;
  --heading-02-font-weight: 300;
  --heading-02-letter-spacing: 0.3px;
  --heading-02-mobile-font-size: 25px;
  --heading-02-mobile-line-height: 30px;
  --heading-02-mobile-font-weight: 300;
  --heading-02-mobile-letter-spacing: var(--heading-02-letter-spacing);
  --heading-03-font-size: 20px;
  --heading-03-line-height: 26px;
  --heading-03-font-weight: 300;
  --heading-03-letter-spacing: 0.3px;
  --heading-03-mobile-font-size: var(--heading-04-font-size);
  --heading-03-mobile-line-height: var(--heading-04-line-height);
  --heading-03-mobile-font-weight: var(--heading-04-font-weight);
  --heading-03-mobile-letter-spacing: var(--heading-04-letter-spacing);
  --heading-04-font-size: 16px;
  --heading-04-line-height: 22px;
  --heading-04-font-weight: 300;
  --heading-04-letter-spacing: 0.3px;
  --heading-04-mobile-font-size: var(--heading-05-font-size);
  --heading-04-mobile-line-height: var(--heading-05-line-height);
  --heading-04-mobile-font-weight: var(--heading-05-font-weight);
  --heading-04-mobile-letter-spacing: var(--heading-05-letter-spacing);
  --heading-05-font-size: 14px;
  --heading-05-line-height: 22px;
  --heading-05-font-weight: 600;
  --heading-05-letter-spacing: 0.2px;
  --heading-05-mobile-font-size: var(--heading-06-font-size);
  --heading-05-mobile-line-height: var(--heading-06-line-height);
  --heading-05-mobile-font-weight: var(--heading-06-font-weight);
  --heading-05-mobile-letter-spacing: var(--heading-06-letter-spacing);
  --heading-06-font-size: 10px;
  --heading-06-line-height: 18px;
  --heading-06-font-weight: 300;
  --heading-06-letter-spacing: 0.2px;
  --heading-06-mobile-font-size: 10px;
  --heading-06-mobile-line-height: 18px;
  --heading-06-mobile-font-weight: 300;
  --heading-06-mobile-letter-spacing: 0.2px;
  --paragraph-01-font-size: 14px;
  --paragraph-01-line-height: 20px;
  --paragraph-01-font-weight: 400;
  --paragraph-01-letter-spacing: 0.2px;
  --paragraph-02-font-size: 13px;
  --paragraph-02-line-height: 18px;
  --paragraph-02-font-weight: 300;
  --paragraph-02-letter-spacing: 0.2px;
  --paragraph-03-font-size: 12px;
  --paragraph-03-line-height: 16px;
  --paragraph-03-font-weight: 400;
  --paragraph-03-letter-spacing: 0;
  --paragraph-04-font-size: 12px;
  --paragraph-04-line-height: 16px;
  --paragraph-04-font-weight: 600;
  --paragraph-04-letter-spacing: 0.2px;
  --paragraph-05-font-size: 12px;
  --paragraph-05-line-height: 16px;
  --paragraph-05-font-weight: 600;
  --paragraph-05-letter-spacing: 0.5px;
  --large-label-font-size: 18px;
  --large-label-line-height: 24px;
  --large-label-font-weight: 400;
  --large-label-letter-spacing: 0.2px;
  --small-label-font-size: 14px;
  --small-label-line-height: 20px;
  --small-label-font-weight: 400;
  --small-label-letter-spacing: 0;
  --button-small-font-size: 12px;
  --button-small-line-height: 12px;
  --button-small-font-weight: 600;
  --button-small-letter-spacing: 1px;
  --button-small-medium-font-size: var(--button-medium-font-size);
  --button-small-medium-line-height: var(--button-medium-line-height);
  --button-small-medium-font-weight: var(--button-medium-font-weight);
  --button-small-medium-letter-spacing: var(--button-medium-letter-spacing);
  --button-medium-font-size: 16px;
  --button-medium-line-height: 18px;
  --button-medium-font-weight: 600;
  --button-medium-letter-spacing: 1px;
  --button-large-font-size: 18px;
  --button-large-line-height: 20px;
  --button-large-font-weight: 600;
  --button-large-letter-spacing: 1px;
  --shadow-small-color: rgba(0, 0, 0, 0.8);
  --shadow-large-color: rgba(0, 0, 0, 0.8);
  --shadow-small: 0 2px 4px var(--shadow-small-color);
  --shadow-large: 0 8px 24px var(--shadow-large-color);
  --accordion-regular-font-size: var(--heading-04-font-size);
  --accordion-regular-line-height: var(--heading-04-line-height);
  --accordion-regular-font-weight: var(--heading-04-font-weight);
  --accordion-regular-letter-spacing: var(--heading-04-letter-spacing);
  --accordion-regular-mobile-font-size: var(--heading-06-font-size);
  --accordion-regular-mobile-line-height: var(--heading-06-line-height);
  --accordion-regular-mobile-font-weight: 400;
  --accordion-regular-mobile-letter-spacing: var(--heading-06-letter-spacing);
  --accordion-light-font-size: var(--paragraph-01-font-size);
  --accordion-light-line-height: var(--paragraph-01-line-height);
  --accordion-light-font-weight: var(--paragraph-01-font-weight);
  --accordion-light-letter-spacing: var(--paragraph-01-letter-spacing);
  --accordion-light-mobile-font-size: var(--paragraph-03-font-size);
  --accordion-light-mobile-line-height: var(--paragraph-03-line-height);
  --accordion-light-mobile-font-weight: var(--paragraph-03-font-weight);
  --accordion-light-mobile-letter-spacing: var(--paragraph-03-letter-spacing);
  --accordion-body-font-size: var(--paragraph-03-font-size);
  --accordion-body-line-height: var(--paragraph-03-line-height);
  --accordion-body-font-weight: var(--paragraph-03-font-weight);
  --accordion-body-letter-spacing: var(--paragraph-03-letter-spacing);
  --accordion-chevron-size: 24px;
  --accordion-regular-chevron-color: var(--interactive-text);
  --accordion-regular-hover-chevron-color: var(--interactive-text);
  --accordion-disabled-color: var(--disabled-01);
  --accordion-regular-header-background-color: var(--ui-03);
  --accordion-regular-header-text-color: var(--text-01);
  --accordion-regular-header-hover-text-color: var(--text-01);
  --accordion-regular-header-top-margin: 16px;
  --accordion-regular-border-color: transparent;
  --accordion-regular-mobile-border-color: var(--ui-04);
  --accordion-regular-mobile-separator-color: var(--ui-04);
  --accordion-regular-header-padding: 10px 14px;
  --accordion-regular-mobile-header-padding: 5px;
  --accordion-regular-body-padding: 10px 14px 8px;
  --accordion-regular-mobile-body-padding: 6px 9px 10px;
  --accordion-light-header-text-color: var(--interactive-text);
  --accordion-light-header-hover-text-color: var(--interactive-text);
  --accordion-light-chevron-color: var(--interactive-text);
  --accordion-light-hover-chevron-color: var(--interactive-text);
  --accordion-light-header-padding: 24px 32px;
  --accordion-light-mobile-header-padding: 16px 0;
  --accordion-light-body-padding: 0 32px 32px;
  --accordion-light-mobile-body-padding: 0 0 24px;
  --accordion-light-border-color: var(--ui-04);
  --accordion-extra-light-header-top-margin: 16px;
  --accordion-extra-light-chevron-color: var(--interactive-text);
  --accordion-extra-light-header-text-color: var(--interactive-text);
  --accordion-extra-light-body-padding: 16px 0 8px;
  --accordion-extra-light-font-size: 16px;
  --accordion-extra-light-line-height: 24px;
  --accordion-extra-light-font-weight: 600;
  --accordion-extra-light-letter-spacing: 1px;
  --action-font-size: var(--paragraph-03-font-size);
  --action-line-height: var(--paragraph-03-line-height);
  --action-font-weight: var(--paragraph-03-font-weight);
  --action-letter-spacing: var(--paragraph-03-letter-spacing);
  --action-color: var(--text-01);
  --action-hover-color: var(--text);
  --action-background-color: var(--sidebar-background-color);
  --action-hover-background-color: #EEF1F3;
  --action-selected-color: var(--text);
  --action-selected-background-color: #EEF1F3;
  --action-icon-size: 20px;
  --action-expand-icon-size: 16px;
  --avatar-background-color: var(--interactive-primary);
  --avatar-color: var(--text-02);
  --avatar-disabled-opacity: 0.4;
  --avatar-xsmall-size: 32px;
  --avatar-xsmall-font-size: var(--paragraph-04-font-size);
  --avatar-xsmall-line-height: var(--paragraph-04-line-height);
  --avatar-xsmall-font-weight: var(--paragraph-04-font-weight);
  --avatar-xsmall-letter-spacing: var(--paragraph-04-letter-spacing);
  --avatar-xsmall-icon-size: 24px;
  --avatar-small-size: 40px;
  --avatar-small-font-size: var(--paragraph-03-font-size);
  --avatar-small-line-height: var(--paragraph-03-line-height);
  --avatar-small-font-weight: 600;
  --avatar-small-letter-spacing: var(--paragraph-03-letter-spacing);
  --avatar-small-icon-size: 24px;
  --avatar-small-medium-size: 64px;
  --avatar-small-medium-font-size: var(--heading-04-font-size);
  --avatar-small-medium-line-height: var(--heading-04-line-height);
  --avatar-small-medium-font-weight: 600;
  --avatar-small-medium-letter-spacing: var(--heading-04-letter-spacing);
  --avatar-small-medium-icon-size: 40px;
  --avatar-medium-size: 80px;
  --avatar-medium-font-size: var(--heading-03-font-size);
  --avatar-medium-line-height: var(--heading-03-line-height);
  --avatar-medium-font-weight: 600;
  --avatar-medium-letter-spacing: var(--heading-03-letter-spacing);
  --avatar-medium-icon-size: 48px;
  --avatar-large-size: 120px;
  --avatar-large-font-size: var(--heading-02-font-size);
  --avatar-large-line-height: var(--heading-02-line-height);
  --avatar-large-font-weight: 600;
  --avatar-large-letter-spacing: var(--heading-02-letter-spacing);
  --avatar-large-icon-size: 72px;
  --avatar-xlarge-size: 160px;
  --avatar-xlarge-font-size: var(--heading-01-font-size);
  --avatar-xlarge-line-height: var(--heading-01-line-height);
  --avatar-xlarge-font-weight: 600;
  --avatar-xlarge-letter-spacing: var(--heading-01-letter-spacing);
  --avatar-xlarge-icon-size: 96px;
  --badge-font-size: var(--paragraph-03-font-size);
  --badge-line-height: var(--paragraph-03-line-height);
  --badge-font-weight: var(--paragraph-03-font-weight);
  --badge-letter-spacing: var(--paragraph-03-letter-spacing);
  --badge-background-color: var(--ui-06);
  --badge-color: var(--text-02);
  --badge-active-background-color: #a2e8f5;
  --badge-active-color: var(--badge-color);
  --badge-positive-background-color: #b3f998;
  --badge-positive-color: var(--badge-color);
  --badge-critical-background-color: #f19b94;
  --badge-critical-color: var(--badge-color);
  --badge-negative-background-color: #f9ee89;
  --badge-negative-color: var(--badge-color);
  --badge-vibrant-background-color: #969696;
  --badge-vibrant-color: var(--text-02);
  --badge-vibrant-active-background-color: #1ab394;
  --badge-vibrant-active-color: var(--badge-vibrant-color);
  --badge-vibrant-positive-background-color: #1c84c6;
  --badge-vibrant-positive-color: var(--badge-vibrant-color);
  --badge-vibrant-critical-background-color: #fb4f61;
  --badge-vibrant-critical-color: var(--badge-vibrant-color);
  --badge-vibrant-negative-background-color: #f8ac59;
  --badge-vibrant-negative-color: var(--badge-vibrant-color);
  --base-label-color: var(--text-01);
  --base-label-small-font-size: var(--small-label-font-size);
  --base-label-small-line-height: var(--small-label-line-height);
  --base-label-small-font-weight: 600;
  --base-label-small-letter-spacing: var(--small-label-letter-spacing);
  --base-label-large-font-size: var(--large-label-font-size);
  --base-label-large-line-height: var(--large-label-line-height);
  --base-label-large-font-weight: 300;
  --base-label-large-letter-spacing: var(--large-label-letter-spacing);
  --base-error-color: var(--danger);
  --base-error-simple-icon-size: 20px;
  --base-error-simple-font-size: var(--paragraph-03-font-size);
  --base-error-simple-line-height: 20px;
  --base-error-simple-font-weight: var(--paragraph-03-font-weight);
  --base-error-simple-letter-spacing: var(--paragraph-03-letter-spacing);
  --button-primary-background-color: var(--interactive-primary);
  --button-primary-border-color: transparent;
  --button-primary-text-color: var(--text-02);
  --button-primary-hover-background-color: var(--hover-primary);
  --button-primary-hover-border-color: transparent;
  --button-primary-hover-text-color: var(--text-02);
  --button-primary-active-background-color: var(--active-primary);
  --button-primary-active-text-color: var(--text-02);
  --button-primary-active-border-color: transparent;
  --button-primary-disabled-background-color: rgba(190, 32, 191, 0.4);
  --button-primary-disabled-text-color: rgba(255, 255, 255, 0.8);
  --button-primary-disabled-border-color: transparent;
  --button-secondary-background-color: transparent;
  --button-secondary-border-color: var(--interactive-primary);
  --button-secondary-text-color: var(--interactive-primary);
  --button-secondary-hover-background-color: var(--hover-primary);
  --button-secondary-hover-border-color: transparent;
  --button-secondary-hover-text-color: var(--text-02);
  --button-secondary-active-background-color: var(--active-primary);
  --button-secondary-active-text-color: var(--text-02);
  --button-secondary-active-border-color: transparent;
  --button-secondary-disabled-background-color: transparent;
  --button-secondary-disabled-text-color: rgba(190, 32, 191, 0.4);
  --button-secondary-disabled-border-color: rgba(190, 32, 191, 0.4);
  --button-tertiary-background-color: transparent;
  --button-tertiary-border-color: transparent;
  --button-tertiary-text-color: var(--button-secondary-text-color);
  --button-tertiary-hover-background-color: var(--hover-secondary);
  --button-tertiary-hover-border-color: var(--hover-secondary);
  --button-tertiary-hover-text-color: var(--text-02);
  --button-tertiary-active-background-color: var(--ui-04);
  --button-tertiary-active-text-color: var(--button-secondary-text-color);
  --button-tertiary-active-border-color: transparent;
  --button-tertiary-disabled-background-color: var(--button-secondary-background-color);
  --button-tertiary-disabled-text-color: var(--button-secondary-disabled-text-color);
  --button-tertiary-disabled-border-color: transparent;
  --button-emphasis-background-color: var(--accent-01);
  --button-emphasis-border-color: transparent;
  --button-emphasis-text-color: var(--text-02);
  --button-emphasis-hover-background-color: #007e5a;
  --button-emphasis-hover-border-color: transparent;
  --button-emphasis-hover-text-color: var(--text-02);
  --button-emphasis-active-background-color: #006548;
  --button-emphasis-active-text-color: var(--text-02);
  --button-emphasis-active-border-color: transparent;
  --button-emphasis-disabled-background-color: rgba(0, 177, 127, 0.4);
  --button-emphasis-disabled-text-color: rgba(255, 255, 255, 0.4);
  --button-emphasis-disabled-border-color: transparent;
  --button-cta-background-color: var(--accent-02);
  --button-cta-border-color: transparent;
  --button-cta-text-color: var(--text-02);
  --button-cta-hover-background-color: #3864ff;
  --button-cta-hover-border-color: transparent;
  --button-cta-hover-text-color: var(--text-02);
  --button-cta-active-background-color: #1f51ff;
  --button-cta-active-text-color: var(--text-02);
  --button-cta-active-border-color: transparent;
  --button-cta-disabled-background-color: rgba(107, 140, 255, 0.4);
  --button-cta-disabled-text-color: rgba(255, 255, 255, 0.4);
  --button-cta-disabled-border-color: transparent;
  --button-primary-danger-background-color: var(--danger);
  --button-primary-danger-border-color: transparent;
  --button-primary-danger-text-color: var(--text-02);
  --button-primary-danger-hover-background-color: #eb5c6a;
  --button-primary-danger-hover-border-color: transparent;
  --button-primary-danger-hover-text-color: var(--text-02);
  --button-primary-danger-active-background-color: #e94959;
  --button-primary-danger-active-text-color: var(--text-02);
  --button-primary-danger-active-border-color: transparent;
  --button-primary-danger-disabled-background-color: rgba(237, 111, 124, 0.4);
  --button-primary-danger-disabled-text-color: rgba(0, 0, 0, 0.4);
  --button-primary-danger-disabled-border-color: transparent;
  --button-secondary-danger-background-color: transparent;
  --button-secondary-danger-border-color: var(--danger);
  --button-secondary-danger-text-color: var(--danger);
  --button-secondary-danger-hover-background-color: #eb5c6a;
  --button-secondary-danger-hover-border-color: transparent;
  --button-secondary-danger-hover-text-color: var(--text-02);
  --button-secondary-danger-active-background-color: #e94959;
  --button-secondary-danger-active-text-color: var(--text-02);
  --button-secondary-danger-active-border-color: transparent;
  --button-secondary-danger-disabled-background-color: transparent;
  --button-secondary-danger-disabled-text-color: rgba(237, 111, 124, 0.4);
  --button-secondary-danger-disabled-border-color: rgba(237, 111, 124, 0.4);
  --button-tertiary-danger-background-color: transparent;
  --button-tertiary-danger-border-color: transparent;
  --button-tertiary-danger-text-color: var(--button-secondary-danger-text-color);
  --button-tertiary-danger-hover-background-color: #333333;
  --button-tertiary-danger-hover-border-color: transparent;
  --button-tertiary-danger-hover-text-color: #eb5c6a;
  --button-tertiary-danger-active-background-color: #474747;
  --button-tertiary-danger-active-text-color: #e94959;
  --button-tertiary-danger-active-border-color: transparent;
  --button-tertiary-danger-disabled-background-color: var(--button-secondary-danger-disabled-background-color);
  --button-tertiary-danger-disabled-text-color: var(--button-secondary-danger-disabled-text-color);
  --button-tertiary-danger-disabled-border-color: transparent;
  --button-text-transform: uppercase;
  --button-border-radius: 4px;
  --button-border-width: 2px;
  --button-small-height: 22px;
  --button-small-medium-height: 32px;
  --button-medium-height: 38px;
  --button-large-height: 62px;
  --button-small-icon-size: 12px;
  --button-small-medium-icon-size: 12px;
  --button-medium-icon-size: 16px;
  --button-large-icon-size: 18px;
  --plain-button-color: var(--link-color);
  --plain-button-hover-color: var(--link-hover-color);
  --plain-button-active-color: var(--link-active-color);
  --plain-button-disabled-color: var(--link-disabled-color);
  --plain-button-danger-color: var(--danger);
  --plain-button-danger-hover-color: #eb5c6a;
  --plain-button-danger-active-color: #e94959;
  --plain-button-danger-disabled-color: rgba(237, 111, 124, 0.4);
  --plain-button-font-size: var(--link-small-font-size);
  --plain-button-line-height: var(--link-small-line-height);
  --plain-button-font-weight: var(--link-small-font-weight);
  --plain-button-letter-spacing: var(--link-small-letter-spacing);
  --plain-button-icon-size: var(--link-small-icon-size);
  --breadcrumb-color: var(--text-01);
  --breadcrumb-font-size: 16px;
  --breadcrumb-line-height: 24px;
  --breadcrumb-letter-spacing: 0;
  --breadcrumb-font-weight: 400;
  --breadcrumb-chevron-color: var(--text-01);
  --breadcrumb-link-color: var(--interactive-text);
  --breadcrumb-link-hover-color: var(--hover-link);
  --breadcrumb-link-active-color: var(--active-primary);
  --icon-button-small-icon-size: 12px;
  --icon-button-small-medium-icon-size: 14px;
  --icon-button-medium-icon-size: 16px;
  --icon-button-large-icon-size: 18px;
  --card-text-color: #676a6c;
  --card-background-color: var(--ui-01);
  --card-border-color: #e7eaec;
  --card-border-width: 1px;
  --card-border-radius: 4px;
  --comparison-table-popular-cell-text-color: var(--text-01);
  --comparison-table-popular-cell-background-color: #9FC131;
  --comparison-table-selected-column-background-color: var(--ui-03);
  --comparison-table-selected-column-text-color: var(--text-01);
  --comparison-table-border-color: var(--ui-04);
  --comparison-table-header-background-color: var(--ui-01);
  --comparison-table-header-text-color: var(--text-01);
  --comparison-table-placeholder-cell-background-color: transparent;
  --comparison-table-placeholder-cell-border-color: transparent;
  --comparison-table-disabled-text-color: var(--disabled-01);
  --comparison-table-disabled-column-header-text-color: var(--comparison-table-disabled-text-color);
  --comparison-table-cell-background-color: var(--ui-01);
  --comparison-table-intersection-cell-background-color: var(--ui-background);
  --comparison-table-toggle-section-header-background-color: var(--ui-background);
  --comparison-table-toggle-section-chevron-size: var(--accordion-chevron-size);
  --comparison-table-toggle-section-chevron-color: var(--accordion-regular-chevron-color);
  --comparison-table-toggle-section-header-padding: 24px 32px 24px 16px;
  --comparison-table-toggle-section-header-padding-rtl: 24px 16px 24px 32px;
  --comparison-table-expansion-cell-color: var(--interactive-text);
  --comparison-table-font-size: 16px;
  --comparison-table-line-height: 20px;
  --comparison-table-font-weight: 400;
  --comparison-table-letter-spacing: 0;
  --comparison-table-popular-cell-font-size: 14px;
  --comparison-table-popular-cell-line-height: 16px;
  --comparison-table-popular-cell-font-weight: 600;
  --comparison-table-popular-cell-letter-spacing: 0.2px;
  --comparison-table-description-cell-mobile-font-size: 14px;
  --comparison-table-description-cell-mobile-line-height: 16px;
  --comparison-table-description-cell-mobile-font-weight: 700;
  --comparison-table-description-cell-mobile-letter-spacing: 0.2px;
  --comparison-table-mobile-section-header-font-size: 14px;
  --comparison-table-mobile-section-header-line-height: 24px;
  --comparison-table-mobile-section-header-font-weight: 600;
  --comparison-table-mobile-section-header-letter-spacing: 0;
  --comparison-table-group-label-font-size: 16px;
  --comparison-table-group-label-line-height: 24px;
  --comparison-table-group-label-font-weight: 600;
  --comparison-table-group-label-letter-spacing: 1px;
  --comparison-table-toggle-section-header-font-size: var(--heading-04-font-size);
  --comparison-table-toggle-section-header-line-height: var(--heading-04-line-height);
  --comparison-table-toggle-section-header-font-weight: var(--heading-04-font-weight);
  --comparison-table-toggle-section-header-letter-spacing: var(--heading-04-letter-spacing);
  --data-display-label-font-weight: 400;
  --data-display-large-font-size: var(--paragraph-02-font-size);
  --data-display-large-line-height: var(--paragraph-02-line-height);
  --data-display-large-font-weight: var(--paragraph-02-font-weight);
  --data-display-large-letter-spacing: var(--paragraph-02-letter-spacing);
  --data-display-large-mobile-font-size: var(--paragraph-03-font-size);
  --data-display-large-mobile-line-height: var(--paragraph-03-line-height);
  --data-display-large-mobile-font-weight: var(--paragraph-03-font-weight);
  --data-display-large-mobile-letter-spacing: var(--paragraph-03-letter-spacing);
  --data-display-medium-font-size: var(--paragraph-03-font-size);
  --data-display-medium-line-height: var(--paragraph-03-line-height);
  --data-display-medium-font-weight: var(--paragraph-03-font-weight);
  --data-display-medium-letter-spacing: var(--paragraph-03-letter-spacing);
  --data-display-small-font-size: var(--paragraph-04-font-size);
  --data-display-small-line-height: var(--paragraph-04-line-height);
  --data-display-small-font-weight: var(--paragraph-04-font-weight);
  --data-display-small-letter-spacing: var(--paragraph-04-letter-spacing);
  --selectable-card-text-color: #676a6c;
  --selectable-card-background-color: var(--ui-01);
  --selectable-card-background-hover-color: var(--ui-02);
  --selectable-card-background-selected-color: var(--ui-02);
  --selectable-card-background-selected-hover-color: var(--ui-03);
  --selectable-card-background-disabled-color: var(--ui-01);
  --selectable-card-background-disabled-selected-color: var(--ui-02);
  --selectable-card-expert-background-hover-color: var(--ui-05);
  --selectable-card-expert-background-selected-color: var(--ui-02);
  --selectable-card-expert-background-disabled-selected-color: var(--ui-02);
  --selectable-card-expert-background-selected-hover-color: var(--ui-03);
  --selectable-card-icon-color: var(--success);
  --selectable-card-unchecked-icon-hover-color: #c2c2c2;
  --selectable-card-icon-placeholder-border-color: var(--ui-05);
  --selectable-card-border-color: #999999;
  --selectable-card-border-hover-color: var(--ui-06);
  --selectable-card-border-selected-color: var(--ui-06);
  --selectable-card-border-disabled-color: var(--ui-03);
  --selectable-card-border-disabled-selected-color: #c3c3c3;
  --selectable-card-border-error-color: var(--danger);
  --selectable-card-border-width: 1px;
  --selectable-card-border-radius: 4px;
  --checkbox-border-width: 2px;
  --checkbox-border-radius: 4px;
  --checkbox-control-size: 24px;
  --checkbox-icon-size: 20px;
  --checkbox-background-color: transparent;
  --checkbox-border-color: var(--ui-05);
  --checkbox-icon-color: var(--text-02);
  --checkbox-indeterminate-color: var(--interactive-primary);
  --checkbox-selected-background-color: var(--interactive-primary);
  --checkbox-selected-border-color: var(--interactive-primary);
  --checkbox-hover-background-color: var(--hover-primary);
  --checkbox-hover-border-color: var(--hover-primary);
  --checkbox-active-background-color: var(--active-primary);
  --checkbox-active-border-color: var(--active-primary);
  --checkbox-disabled-border-color: rgba(118, 118, 118, 0.3);
  --checkbox-disabled-background-color: var(--ui-01);
  --checkbox-disabled-selected-background-color: rgba(118, 118, 118, 0.3);
  --checkbox-disabled-icon-color: var(--text-02);
  --checkbox-label-color: var(--text-01);
  --checkbox-label-disabled-color: var(--disabled-01);
  --checkbox-large-label-control-margin-top: 2px;
  --checkbox-large-label-text-margin-top: 0;
  --checkbox-large-label-text-margin-left: 12px;
  --checkbox-small-label-control-margin-top: 0;
  --checkbox-small-label-text-margin-top: 0;
  --checkbox-small-label-text-margin-left: 12px;
  --checkbox-large-label-font-size: var(--large-label-font-size);
  --checkbox-large-label-line-height: var(--large-label-line-height);
  --checkbox-large-label-font-weight: var(--large-label-font-weight);
  --checkbox-large-label-letter-spacing: var(--large-label-letter-spacing);
  --checkbox-small-label-font-size: var(--small-label-font-size);
  --checkbox-small-label-line-height: var(--small-label-line-height);
  --checkbox-small-label-font-weight: var(--small-label-font-weight);
  --checkbox-small-label-letter-spacing: var(--small-label-letter-spacing);
  --checkbox-disabled-negative-background-color: var(--negative-dimmed);
  --circle-toggle-checkmark-background-color: var(--accent-01);
  --circle-toggle-checkmark-color: #ffffff;
  --circle-toggle-checkmark-border-color: var(--ui-background);
  --circle-toggle-icon-color: var(--interactive-primary);
  --circle-toggle-border-color: var(--interactive-primary);
  --circle-toggle-background-color: transparent;
  --circle-toggle-selected-icon-color: #ffffff;
  --circle-toggle-selected-border-color: var(--interactive-primary);
  --circle-toggle-selected-background-color: var(--interactive-primary);
  --circle-toggle-hover-icon-color: #ffffff;
  --circle-toggle-hover-border-color: var(--hover-primary);
  --circle-toggle-hover-background-color: var(--hover-primary);
  --circle-toggle-label-font-size: var(--small-label-font-size);
  --circle-toggle-label-line-height: var(--small-label-line-height);
  --circle-toggle-label-font-weight: var(--small-label-font-weight);
  --circle-toggle-label-letter-spacing: var(--small-label-letter-spacing);
  --circle-toggle-label-mobile-font-size: var(--paragraph-04-font-size);
  --circle-toggle-label-mobile-line-height: var(--paragraph-04-line-height);
  --circle-toggle-label-mobile-font-weight: var(--circle-toggle-label-font-weight);
  --circle-toggle-label-mobile-letter-spacing: var(--paragraph-04-letter-spacing);
  --circle-toggle-hint-font-size: var(--circle-toggle-label-font-size);
  --circle-toggle-hint-line-height: var(--circle-toggle-label-line-height);
  --circle-toggle-hint-font-weight: var(--circle-toggle-label-font-weight);
  --circle-toggle-hint-letter-spacing: var(--circle-toggle-label-letter-spacing);
  --circle-toggle-hint-mobile-font-size: var(--circle-toggle-label-mobile-font-size);
  --circle-toggle-hint-mobile-line-height: var(--circle-toggle-label-mobile-line-height);
  --circle-toggle-hint-mobile-font-weight: 300;
  --circle-toggle-hint-mobile-letter-spacing: var(--circle-toggle-label-mobile-letter-spacing);
  --circle-toggle-label-color: var(--text-01);
  --circle-toggle-hint-color: var(--text-01);
  --circle-toggle-content-text-font-size: var(--heading-05-font-size);
  --circle-toggle-content-text-line-height: var(--heading-05-line-height);
  --circle-toggle-content-text-font-weight: 400;
  --circle-toggle-content-text-letter-spacing: var(--heading-05-letter-spacing);
  --circle-toggle-content-text-mobile-font-size: var(--heading-05-mobile-font-size);
  --circle-toggle-content-text-mobile-line-height: var(--heading-05-mobile-line-height);
  --circle-toggle-content-text-mobile-font-weight: 400;
  --circle-toggle-content-text-mobile-letter-spacing: var(--heading-05-mobile-letter-spacing);
  --circle-toggle-content-text-color: var(--interactive-text);
  --circle-toggle-selected-content-text-color: #ffffff;
  --code-input-font-size: var(--heading-04-font-size);
  --code-input-line-height: var(--heading-04-line-height);
  --code-input-font-weight: 600;
  --code-input-letter-spacing: var(--heading-04-letter-spacing);
  --code-input-color: var(--text-01);
  --code-input-active-color: var(--interactive-primary);
  --code-input-error-color: var(--danger);
  --code-input-disabled-color: var(--disabled-01);
  --context-menu-font-size: var(--paragraph-03-font-size);
  --context-menu-line-height: var(--paragraph-03-line-height);
  --context-menu-font-weight: var(--paragraph-03-font-weight);
  --context-menu-letter-spacing: var(--paragraph-03-letter-spacing);
  --context-menu-background-color: var(--ui-02);
  --context-menu-item-text-color: var(--text-01);
  --context-menu-item-active-color: var(--interactive-text);
  --context-menu-item-disabled-color: var(--disabled-01);
  --context-menu-item-icon-size: 20px;
  --context-menu-item-expand-icon-size: 20px;
  --datefield-toggle-icon-size: var(--formfield-icon-size);
  --datefield-change-view-font-size: var(--small-label-font-size);
  --datefield-change-view-line-height: 1;
  --datefield-change-view-font-weight: bold;
  --datefield-change-view-letter-spacing: var(--small-label-letter-spacing);
  --datefield-change-view-color: var(--interactive-text);
  --datefield-change-view-hover-color: var(--hover-primary);
  --datefield-cell-font-size: var(--paragraph-03-font-size);
  --datefield-cell-line-height: var(--paragraph-03-line-height);
  --datefield-cell-font-weight: 600;
  --datefield-cell-letter-spacing: var(--paragraph-03-letter-spacing);
  --datefield-cell-color: var(--text-01);
  --datefield-cell-selected-background-color: var(--interactive-primary);
  --datefield-cell-selected-color: var(--text-02);
  --datefield-cell-hover-background-color: var(--ui-03);
  --datefield-cell-hover-color: var(--text-01);
  --datefield-cell-disabled-color: var(--disabled-01);
  --datefield-cell-focused-background-color: var(--ui-01);
  --datefield-header-cell-color: #c3c3c3;
  --datefield-period-label-font-size: var(--large-label-font-size);
  --datefield-period-label-line-height: var(--datefield-controls-icon-size);
  --datefield-period-label-font-weight: 600;
  --datefield-period-label-letter-spacing: var(--large-label-letter-spacing);
  --datefield-period-label-color: var(--text-01);
  --datefield-controls-color: var(--interactive-text);
  --datefield-controls-hover-color: var(--hover-primary);
  --datefield-controls-icon-size: 24px;
  --datefield-panel-background-color: var(--ui-01);
  --datefield-panel-header-background-color: var(--ui-01);
  --datefield-panel-border-color: var(--ui-04);
  --dropdown-panel-background-color: var(--ui-02);
  --dropdown-panel-header-background-color: var(--interactive-text);
  --dropdown-panel-text-color: var(--text-01);
  --dropdown-panel-header-text-color: #FFFFFFFF;
  --dropdown-group-section-background-color: var(--ui-03);
  --dropdown-item-text-color: var(--text-01);
  --dropdown-item-selected-color: var(--text-01);
  --dropdown-item-hover-color: var(--hover-primary);
  --dropdown-item-checkmark-color: var(--text-01);
  --dropdown-item-font-size: var(--large-label-font-size);
  --dropdown-item-line-height: var(--large-label-line-height);
  --dropdown-item-font-weight: var(--large-label-font-weight);
  --dropdown-item-letter-spacing: var(--large-label-letter-spacing);
  --dropdown-item-outline-font-size: var(--small-label-font-size);
  --dropdown-item-outline-line-height: var(--small-label-line-height);
  --dropdown-item-outline-font-weight: var(--small-label-font-weight);
  --dropdown-item-outline-letter-spacing: var(--small-label-letter-spacing);
  --dropdown-item-text-color-disabled: var(--disabled-01);
  --dropdown-chevron-size: var(--formfield-icon-size);
  --dropdown-filter-input-text-color: var(--text-01);
  --dropdown-filter-input-border-color: var(--ui-05);
  --dropdown-filter-input-active-text-color: var(--interactive-primary);
  --dropdown-filter-input-active-border-color: var(--interactive-primary);
  --dropdown-item-divider: #d9d9d9;
  --footer-font-size: var(--paragraph-03-font-size);
  --footer-line-height: var(--paragraph-03-line-height);
  --footer-font-weight: var(--paragraph-03-font-weight);
  --footer-letter-spacing: var(--paragraph-03-letter-spacing);
  --footer-color: var(--ui-05);
  --footer-copyright-font-weight: 700;
  --footer-padding-top: 32px;
  --footer-padding-bottom: 32px;
  --footer-mobile-padding-top: 48px;
  --footer-mobile-padding-bottom: 48px;
  --formfield-bottom-padding: 24px;
  --formfield-icon-size: 24px;
  --formfield-label-color: var(--text-01);
  --formfield-control-color: var(--text-01);
  --formfield-border-width: 1px;
  --formfield-active-color: var(--interactive-text);
  --formfield-hover-border-color: var(--ui-06);
  --formfield-disabled-text-color: var(--disabled-01);
  --formfield-disabled-border-color: var(--disabled-01);
  --formfield-readonly-border-color: var(--readonly-02);
  --formfield-readonly-background-color: var(--readonly-01);
  --formfield-control-height: 32px;
  --formfield-error-input-font-weight: 700;
  --formfield-input-padding: 0 0 3px 0;
  --formfield-border-color: var(--ui-03);
  --formfield-active-border-color: var(--interactive-primary);
  --formfield-background-color: transparent;
  --formfield-control-font-size: var(--paragraph-01-font-size);
  --formfield-control-line-height: var(--paragraph-01-line-height);
  --formfield-control-font-weight: 600;
  --formfield-control-letter-spacing: var(--paragraph-01-letter-spacing);
  --formfield-label-font-size: var(--paragraph-05-font-size);
  --formfield-label-line-height: var(--paragraph-05-line-height);
  --formfield-label-font-weight: var(--paragraph-05-font-weight);
  --formfield-label-letter-spacing: var(--paragraph-05-letter-spacing);
  --formfield-label-height: 16px;
  --formfield-floating-distance: -16px;
  --formfield-placeholder-label-font-size: var(--formfield-control-font-size);
  --formfield-placeholder-label-line-height: var(--formfield-control-line-height);
  --formfield-placeholder-label-font-weight: 300;
  --formfield-placeholder-label-letter-spacing: var(--formfield-control-letter-spacing);
  --formfield-input-native-placeholder-font-size: var(--formfield-control-font-size);
  --formfield-input-native-placeholder-line-height: var(--formfield-control-line-height);
  --formfield-input-native-placeholder-font-weight: 200;
  --formfield-input-native-placeholder-letter-spacing: var(--formfield-control-letter-spacing);
  --formfield-input-native-placeholder-color: var(--ui-05);
  --formfield-hint-spacing: 8px;
  --formfield-hint-font-size: var(--paragraph-05-font-size);
  --formfield-hint-line-height: var(--paragraph-05-line-height);
  --formfield-hint-font-weight: 400;
  --formfield-hint-letter-spacing: var(--paragraph-05-letter-spacing);
  --formfield-outline-control-height: 32px;
  --formfield-outline-border-color: var(--ui-05);
  --formfield-outline-background-color: var(--ui-01);
  --formfield-outline-active-border-color: var(--interactive-text);
  --formfield-outline-focus-box-shadow: 0 0 0 2px var(--ui-background);
  --formfield-outline-control-font-size: var(--paragraph-03-font-size);
  --formfield-outline-control-line-height: var(--paragraph-03-line-height);
  --formfield-outline-control-font-weight: var(--paragraph-03-font-weight);
  --formfield-outline-control-letter-spacing: var(--paragraph-03-letter-spacing);
  --formfield-outline-label-height: 28px;
  --formfield-outline-floating-distance: -29px;
  --formfield-outline-hint-spacing: 8px;
  --formfield-outline-label-font-size: var(--paragraph-03-font-size);
  --formfield-outline-label-line-height: var(--paragraph-03-line-height);
  --formfield-outline-label-font-weight: var(--paragraph-03-font-weight);
  --formfield-outline-label-letter-spacing: var(--paragraph-03-letter-spacing);
  --formfield-outline-hint-font-size: var(--paragraph-03-font-size);
  --formfield-outline-hint-line-height: 1em;
  --formfield-outline-hint-font-weight: var(--paragraph-03-font-weight);
  --formfield-outline-hint-letter-spacing: var(--paragraph-03-letter-spacing);
  --formfield-outline-input-native-placeholder-font-size: var(--formfield-outline-control-font-size);
  --formfield-outline-input-native-placeholder-line-height: var(--formfield-outline-control-line-height);
  --formfield-outline-input-native-placeholder-font-weight: 200;
  --formfield-outline-input-native-placeholder-letter-spacing: var(--formfield-outline-control-letter-spacing);
  --formfield-outline-input-native-placeholder-color: var(--ui-05);
  --header-font-size: var(--paragraph-03-font-size);
  --header-line-height: var(--paragraph-03-line-height);
  --header-font-weight: var(--paragraph-03-font-weight);
  --header-letter-spacing: var(--paragraph-03-letter-spacing);
  --header-link-active-color: var(--hover-primary);
  --header-link-active-font-weight: 700;
  --header-background-color: var(--ui-01);
  --header-border-color: #e7eaec;
  --icon-filled-background-color: var(--text-01);
  --icon-filled-color: var(--ui-01);
  --indicator-background-color: #f19b94;
  --indicator-color: var(--text-02);
  --indicator-border-color: var(--ui-01);
  --indicator-font-size: var(--paragraph-05-font-size);
  --indicator-line-height: 11px;
  --indicator-font-weight: var(--paragraph-05-font-weight);
  --indicator-letter-spacing: 0;
  --link-large-font-size: var(--heading-03-font-size);
  --link-large-line-height: var(--heading-03-line-height);
  --link-large-font-weight: 600;
  --link-large-letter-spacing: var(--heading-03-letter-spacing);
  --link-large-icon-size: 32px;
  --link-small-font-size: 14px;
  --link-small-line-height: 20px;
  --link-small-font-weight: 600;
  --link-small-letter-spacing: 1px;
  --link-small-icon-size: 14px;
  --link-color: var(--interactive-text);
  --link-hover-color: var(--hover-link);
  --link-active-color: var(--active-primary);
  --link-disabled-color: var(--disabled-04);
  --link-black-color: var(--text-01);
  --link-black-hover-color: #9a9a9a;
  --link-black-active-color: #5c5c5c;
  --link-black-disabled-color: var(--disabled-01);
  --bs-link-hover-color: #841e70;
  --list-text-color: var(--text-01);
  --list-circle-text-color: var(--text-02);
  --list-icon-color: white;
  --list-icon-size: 24px;
  --list-circle-size: 24px;
  --list-negative-circle-text-color: var(--negative-background);
  --menu-background-color: var(--ui-01);
  --menu-item-border-color: var(--ui-04);
  --menu-button-background-color: var(--ui-01);
  --menu-button-hover-background-color: var(--ui-03);
  --menu-button-text-color: var(--text-01);
  --menu-button-expanded-background-color: var(--interactive-primary);
  --menu-button-expanded-border-color: var(--interactive-primary);
  --menu-button-expanded-text-color: var(--text-02);
  --menu-button-nested-background-color: var(--ui-02);
  --menu-button-nested-expanded-background-color: #66c4e3;
  --menu-button-nested-expanded-border-color: #66c4e3;
  --menu-button-nested-expanded-color: var(--text-02);
  --message-text-color: var(--text-01);
  --message-close-icon-color: var(--message-text-color);
  --message-box-shadow: var(--shadow-small);
  --message-border-radius: 4px;
  --message-regular-background-color: var(--ui-01);
  --message-regular-border-color: var(--text-01);
  --message-info-icon-color: var(--info);
  --message-info-background-color: #d1ecf1;
  --message-info-border-color: #bee5eb;
  --message-error-icon-color: var(--danger);
  --message-error-background-color: #f8d7da;
  --message-error-border-color: #f5c6cb;
  --message-success-icon-color: var(--success);
  --message-success-background-color: #d4edda;
  --message-success-border-color: #c3e6cb;
  --message-warning-icon-color: var(--warning);
  --message-warning-background-color: #fff3cd;
  --message-warning-border-color: #ffeeba;
  --message-font-size: var(--paragraph-03-font-size);
  --message-line-height: var(--paragraph-03-line-height);
  --message-font-weight: var(--paragraph-03-font-weight);
  --message-letter-spacing: var(--paragraph-03-letter-spacing);
  --message-banner-border-radius: 0;
  --modal-background-color: var(--ui-03);
  --modal-text-color: var(--text-01);
  --modal-close-icon-color: var(--text-01);
  --modal-actions-background-color: var(--ui-02);
  --modal-actions-border-color: var(--ui-04);
  --pagination-link-color: var(--dark-text);
  --pagination-link-disabled-color: var(--disabled-04);
  --pagination-item-font-weight: 600;
  --pagination-text-color: var(--text-01);
  --pagination-icon-size: 24px;
  --pagination-icon-font-weight: 600;
  --pagination-font-size: var(--paragraph-03-font-size);
  --pagination-line-height: var(--paragraph-03-line-height);
  --pagination-font-weight: var(--paragraph-03-font-weight);
  --pagination-letter-spacing: var(--paragraph-03-letter-spacing);
  --pagination-compact-direction-font-size: var(--link-small-font-size);
  --pagination-compact-direction-line-height: var(--link-small-line-height);
  --pagination-compact-direction-font-weight: var(--link-small-font-weight);
  --pagination-compact-direction-letter-spacing: var(--link-small-letter-spacing);
  --natural-language-form-large-font-size: var(--heading-02-font-size);
  --natural-language-form-large-line-height: 52px;
  --natural-language-form-large-font-weight: var(--heading-02-font-weight);
  --natural-language-form-large-letter-spacing: var(--heading-02-letter-spacing);
  --natural-language-form-small-font-size: var(--heading-04-font-size);
  --natural-language-form-small-line-height: 36px;
  --natural-language-form-small-font-weight: var(--heading-04-font-weight);
  --natural-language-form-small-letter-spacing: var(--heading-04-letter-spacing);
  --natural-language-form-text-color: var(--text-01);
  --natural-language-form-border-color: var(--ui-04);
  --natural-language-form-active-color: var(--formfield-active-color);
  --natural-language-form-active-border-color: var(--interactive-primary);
  --natural-language-form-error-color: var(--danger);
  --natural-language-form-error-border-color: var(--danger);
  --notification-panel-background-color: var(--ui-01);
  --notification-panel-item-unread-background-color: var(--ui-02);
  --notification-panel-item-unread-hover-background-color: var(--ui-03);
  --notification-panel-item-unread-active-background-color: var(--ui-04);
  --notification-panel-item-read-background-color: var(--ui-01);
  --notification-panel-item-read-hover-background-color: var(--ui-02);
  --notification-panel-item-read-active-background-color: var(--ui-03);
  --number-stepper-label-font-size: var(--large-label-font-size);
  --number-stepper-label-line-height: var(--large-label-line-height);
  --number-stepper-label-font-weight: var(--large-label-font-weight);
  --number-stepper-label-letter-spacing: var(--large-label-letter-spacing);
  --number-stepper-large-font-size: var(--heading-02-font-size);
  --number-stepper-large-line-height: var(--heading-02-line-height);
  --number-stepper-large-font-weight: 400;
  --number-stepper-large-letter-spacing: var(--heading-02-letter-spacing);
  --number-stepper-small-font-size: var(--paragraph-01-font-size);
  --number-stepper-small-line-height: var(--paragraph-01-line-height);
  --number-stepper-small-font-weight: var(--paragraph-01-font-weight);
  --number-stepper-small-letter-spacing: var(--paragraph-01-letter-spacing);
  --number-stepper-color: var(--text-01);
  --number-stepper-underline-color: var(--text-01);
  --number-stepper-disabled-color: var(--formfield-disabled-text-color);
  --number-stepper-disabled-underline-color: var(--formfield-disabled-border-color);
  --page-search-font-size: var(--heading-03-font-size);
  --page-search-line-height: var(--heading-03-line-height);
  --page-search-font-weight: 600;
  --page-search-letter-spacing: var(--heading-03-letter-spacing);
  --page-search-icon-color: var(--interactive-primary);
  --page-search-background-color: var(--ui-02);
  --page-search-box-shadow: none;
  --popover-content-font-size: var(--paragraph-03-font-size);
  --popover-content-line-height: var(--paragraph-03-line-height);
  --popover-content-font-weight: var(--paragraph-03-font-weight);
  --popover-content-letter-spacing: var(--paragraph-03-letter-spacing);
  --popover-close-icon-size: 16px;
  --popover-text-color: var(--text-01);
  --popover-background-color: var(--ui-01);
  --popover-border-color: var(--ui-04);
  --popover-close-icon-color: var(--ui-04);
  --progressbar-background-color: var(--ui-03);
  --progressbar-indicator-color: var(--interactive-primary);
  --progress-indicator-mobile-color: var(--text-01);
  --progress-indicator-mobile-font-size: var(--paragraph-03-font-size);
  --progress-indicator-mobile-line-height: var(--paragraph-03-line-height);
  --progress-indicator-mobile-font-weight: var(--paragraph-03-font-weight);
  --progress-indicator-mobile-letter-spacing: var(--paragraph-03-letter-spacing);
  --progress-stepper-label-font-size: var(--large-label-font-size);
  --progress-stepper-label-line-height: var(--large-label-line-height);
  --progress-stepper-label-font-weight: 300;
  --progress-stepper-label-letter-spacing: var(--large-label-letter-spacing);
  --progress-stepper-step-font-size: var(--paragraph-03-font-size);
  --progress-stepper-step-line-height: var(--paragraph-03-line-height);
  --progress-stepper-step-font-weight: var(--paragraph-03-font-weight);
  --progress-stepper-step-letter-spacing: var(--paragraph-03-letter-spacing);
  --progress-stepper-dotnumber-font-size: var(--paragraph-04-font-size);
  --progress-stepper-dotnumber-line-height: var(--paragraph-04-line-height);
  --progress-stepper-dotnumber-font-weight: 700;
  --progress-stepper-dotnumber-letter-spacing: var(--paragraph-04-letter-spacing);
  --progress-stepper-step-color: var(--interactive-text);
  --progress-stepper-step-disabled-color: var(--disabled-02);
  --progress-stepper-dot-color: var(--text-02);
  --progress-stepper-dot-background-color: var(--interactive-primary);
  --progress-stepper-dot-disabled-background-color: var(--disabled-02);
  --single-stepper-step-color: var(--text-01);
  --single-stepper-step-font-size: var(--paragraph-03-font-size);
  --single-stepper-step-line-height: var(--paragraph-03-line-height);
  --single-stepper-step-font-weight: 300;
  --single-stepper-step-letter-spacing: var(--paragraph-03-letter-spacing);
  --multi-stepper-line-color: var(--disabled-01);
  --multi-stepper-line-filled-color: var(--interactive-primary);
  --multi-stepper-dot-touched-color: var(--interactive-primary);
  --multi-stepper-dot-checkmark-color: var(--ui-01);
  --multi-stepper-dot-background-color: var(--ui-01);
  --multi-stepper-dot-disabled-color: var(--disabled-01);
  --multi-stepper-step-color: var(--text-01);
  --multi-stepper-step-disabled-color: var(--disabled-01);
  --multi-stepper-step-font-size: var(--paragraph-03-font-size);
  --multi-stepper-step-line-height: var(--paragraph-03-line-height);
  --multi-stepper-step-font-weight: var(--paragraph-03-font-weight);
  --multi-stepper-step-letter-spacing: var(--paragraph-03-letter-spacing);
  --multi-stepper-group-color: var(--multi-stepper-step-color);
  --multi-stepper-group-disabled-color: var(--multi-stepper-step-disabled-color);
  --multi-stepper-group-font-size: var(--paragraph-01-font-size);
  --multi-stepper-group-line-height: var(--paragraph-01-line-height);
  --multi-stepper-group-font-weight: var(--paragraph-01-font-weight);
  --multi-stepper-group-letter-spacing: var(--paragraph-01-letter-spacing);
  --radio-button-circle-color: var(--ui-05);
  --radio-button-hover-circle-color: var(--hover-primary);
  --radio-button-hover-dot-color: var(--hover-primary);
  --radio-button-selected-dot-color: var(--interactive-primary);
  --radio-button-selected-circle-color: var(--interactive-primary);
  --radio-button-dot-background-color: transparent;
  --radio-button-selected-background-color: transparent;
  --radio-button-dot-size: 8px;
  --radio-button-disabled-selected-background-color: transparent;
  --radio-button-disabled-selected-dot-color: var(--disabled-01);
  --radio-button-disabled-text-color: var(--disabled-01);
  --radio-button-disabled-border-color: var(--disabled-01);
  --radio-button-label-color: var(--text-01);
  --radio-button-large-label-font-size: var(--large-label-font-size);
  --radio-button-large-label-line-height: var(--large-label-line-height);
  --radio-button-large-label-font-weight: var(--large-label-font-weight);
  --radio-button-large-label-letter-spacing: var(--large-label-letter-spacing);
  --radio-button-small-label-font-size: var(--small-label-font-size);
  --radio-button-small-label-line-height: var(--small-label-line-height);
  --radio-button-small-label-font-weight: var(--small-label-font-weight);
  --radio-button-small-label-letter-spacing: var(--small-label-letter-spacing);
  --radio-button-large-label-margin-top: 0;
  --radio-button-large-label-margin-left: 12px;
  --radio-button-large-circle-margin-top: 2px;
  --radio-button-small-label-margin-top: 0;
  --radio-button-small-label-margin-left: 12px;
  --radio-button-small-circle-margin-top: 0;
  --slider-background-color: var(--ui-04);
  --slider-disabled-background-color: var(--disabled-01);
  --slider-indicator-color: var(--interactive-primary);
  --slider-indicator-disabled-color: var(--disabled-04);
  --slider-label-color: var(--text-01);
  --slider-handle-value-color: var(--interactive-text);
  --slider-handle-background-color: #D3D3D3;
  --slider-handle-value-disabled-color: var(--text-02);
  --slider-handle-value-border-color: var(--ui-02);
  --slider-value-label-color: var(--text-01);
  --slider-value-background-color: #D3D3D3;
  --slider-value-label-disabled-color: var(--disabled-02);
  --slider-value-disabled-background-color: var(--disabled-01);
  --slider-handle-border-color: var(--ui-02);
  --slider-handle-focus-border-color: var(--interactive-primary);
  --slider-handle-active-inset-color: rgba(39, 171, 214, 0.2);
  --slider-label-font-size: var(--base-label-large-font-size);
  --slider-label-line-height: var(--base-label-large-line-height);
  --slider-label-letter-spacing: var(--base-label-large-letter-spacing);
  --slider-label-font-weight: var(--base-label-large-font-weight);
  --slider-value-label-font-size: var(--paragraph-03-font-size);
  --slider-value-label-line-height: var(--paragraph-03-line-height);
  --slider-value-label-letter-spacing: var(--paragraph-03-letter-spacing);
  --slider-value-label-font-weight: 300;
  --slider-handle-value-font-size: var(--paragraph-05-font-size);
  --slider-handle-value-line-height: var(--paragraph-05-line-height);
  --slider-handle-value-font-weight: var(--paragraph-05-font-weight);
  --slider-handle-value-letter-spacing: var(--paragraph-05-letter-spacing);
  --slider-negative-background-color: var(--negative-dimmed);
  --small-stage-min-height: 208px;
  --small-stage-image-min-height: 64px;
  --small-stage-image-max-height: 144px;
  --small-stage-header-height: 64px;
  --small-stage-header-padding: 16px 0 24px;
  --small-stage-mobile-header-height: 32px;
  --small-stage-mobile-header-padding: 16px 0;
  --small-stage-image-bottom-height: 15vw;
  --small-stage-image-bottom-margin-top: 24px;
  --small-stage-image-bottom-max-height: 100px;
  --small-stage-content-margin-bottom: 64px;
  --small-stage-mobile-content-margin-bottom: 32px;
  --small-stage-text-color: var(--text-01);
  --small-stage-default-background-color: var(--ui-03);
  --small-stage-expert-header-height: 0;
  --small-stage-expert-header-padding: 16px 0 8px;
  --small-stage-expert-content-min-height: 72px;
  --small-stage-expert-content-margin-top: 8px;
  --small-stage-expert-content-margin-bottom: 24px;
  --small-stage-expert-mobile-content-margin-bottom: 16px;
  --small-stage-expert-image-min-height: 100px;
  --small-stage-expert-image-mobile-height: 15vw;
  --small-stage-expert-mobile-header-padding: 8px 0;
  --rating-icon-color: var(--interactive-primary);
  --rating-icon-size: 40px;
  --rating-label-color: var(--text-01);
  --rating-disabled-icon-color: var(--disabled-01);
  --rating-disabled-label-color: var(--disabled-01);
  --rating-label-font-size: var(--paragraph-03-font-size);
  --rating-label-line-height: var(--paragraph-03-line-height);
  --rating-label-font-weight: var(--paragraph-03-font-weight);
  --rating-label-letter-spacing: var(--paragraph-03-letter-spacing);
  --spinner-background-color: var(--ui-03);
  --spinner-indicator-color: var(--interactive-primary);
  --spinner-negative-background-color: rgba(150, 150, 150, 0.4);
  --spinner-negative-indicator-color: var(--negative);
  --switcher-large-label-control-margin-top: 2px;
  --switcher-large-label-text-margin-top: 0;
  --switcher-small-label-control-margin-top: 0;
  --switcher-small-label-text-margin-top: 0;
  --switcher-big-large-label-control-margin-top: 0;
  --switcher-big-large-label-text-margin-top: 2px;
  --switcher-big-small-label-control-margin-top: 0;
  --switcher-big-small-label-text-margin-top: 4px;
  --switcher-large-font-size: var(--large-label-font-size);
  --switcher-large-line-height: var(--large-label-line-height);
  --switcher-large-font-weight: var(--large-label-font-weight);
  --switcher-large-letter-spacing: var(--large-label-letter-spacing);
  --switcher-small-font-size: var(--small-label-font-size);
  --switcher-small-line-height: var(--small-label-line-height);
  --switcher-small-font-weight: var(--small-label-font-weight);
  --switcher-small-letter-spacing: var(--small-label-letter-spacing);
  --switcher-checked-background-color: var(--interactive-primary);
  --switcher-checked-icon-color: var(--interactive-text);
  --switcher-dot-background-color: #ffffff;
  --switcher-unchecked-background-color: var(--ui-03);
  --switcher-disabled-background-color: var(--disabled-01);
  --switcher-disabled-dot-background-color: var(--disabled-02);
  --switcher-disabled-icon-color: var(--disabled-01);
  --switcher-disabled-text-color: var(--disabled-01);
  --taglist-font-size: var(--paragraph-04-font-size);
  --taglist-line-height: 16px;
  --taglist-font-weight: var(--paragraph-04-font-weight);
  --taglist-letter-spacing: 1px;
  --taglist-close-icon-size: 16px;
  --taglist-color: var(--active-primary);
  --taglist-border-color: var(--active-primary);
  --taglist-background-color: transparent;
  --taglist-hover-color: var(--text-02);
  --taglist-hover-border-color: #1ab394;
  --taglist-hover-background-color: #1ab394;
  --tab-item-color: var(--interactive-text);
  --tab-item-active-color: var(--active-primary);
  --tab-item-hover-color: var(--hover-link);
  --tab-item-border-color: var(--ui-05);
  --tab-item-active-border-color: var(--interactive-text);
  --tab-item-disabled-color: var(--disabled-01);
  --tab-item-disabled-border-color: var(--disabled-01);
  --tab-item-font-size: var(--paragraph-02-font-size);
  --tab-item-line-height: var(--paragraph-02-line-height);
  --tab-item-font-weight: var(--paragraph-02-font-weight);
  --tab-item-letter-spacing: var(--paragraph-02-letter-spacing);
  --tab-expert-color: var(--text-01);
  --tab-expert-bottom-border-color: #dadada;
  --tab-item-disabled-negative-color: var(--negative-dimmed);
  --tab-item-disabled-negative-border-color: var(--negative-dimmed);
  --table-border-bottom-color: #D7D7D7;
  --table-header-cell-font-size: var(--paragraph-03-font-size);
  --table-header-cell-line-height: var(--paragraph-03-line-height);
  --table-header-cell-font-weight: 600;
  --table-header-cell-letter-spacing: var(--paragraph-03-letter-spacing);
  --table-header-cell-color: var(--text-01);
  --table-header-border-top-color: var(--ui-06);
  --table-header-border-bottom-color: var(--ui-06);
  --table-cell-font-size: var(--paragraph-03-font-size);
  --table-cell-line-height: var(--paragraph-03-line-height);
  --table-cell-font-weight: var(--paragraph-03-font-weight);
  --table-cell-letter-spacing: var(--paragraph-03-letter-spacing);
  --table-cell-border-top-color: var(--ui-06);
  --table-cell-color: var(--text-01);
  --table-header-cell-background-color: #F8F8F8;
  --table-row-background-color: transparent;
  --table-sticky-column-background-color: var(--ui-03);
  --selectable-table-row-hover-background-color: var(--ui-02);
  --selectable-table-row-active-background-color: var(--ui-02);
  --selectable-table-row-selected-hover-background-color: var(--ui-02);
  --selectable-table-row-selected-border-color: var(--active-primary);
  --expandable-table-arrow-icon-color: var(--text-01);
  --zebra-table-even-row-background-color:#ffffff;
  --zebra-table-odd-row-background-color: #ffffff;
  --zebra-table-row-active-background-color: var(--ui-04);
  --zebra-table-row-hover-background-color: var(--ui-03);
  --zebra-table-row-selected-background-color: #595959;
  --zebra-table-cell-border-top-color: var(--ui-02);
  --toggle-button-unselected-border-color: #F8F8F8;
  --toggle-button-unselected-text-color: var(--text);
  --toggle-button-unselected-background-color: #F8F8F8;
  --toggle-button-selected-border-color: #5E6E7A;
  --toggle-button-selected-background-color: #5E6E7A;
  --toggle-button-selected-text-color: var(--text-02);
  --toggle-button-hover-background-color: #5E6E7A;
  --toggle-button-hover-border-color: #5E6E7A;
  --toggle-button-hover-text-color: var(--text-02);
  --toggle-button-active-background-color: var(--active-primary);
  --toggle-button-active-border-color: var(--active-primary);
  --toggle-button-active-text-color: var(--text-02);
  --toggle-button-disabled-background-color: #F8F8F8;
  --toggle-button-disabled-border-color: #F8F8F8;
  --toggle-button-disabled-text-color: rgba(125, 125, 125, 0.4);
  --toggle-button-disabled-selected-background-color: rgba(94, 110, 122, 0.4);
  --toggle-button-disabled-selected-border-color: transparent;
  --toggle-button-disabled-selected-text-color: var(--text-02);
  --toggle-button-large-font-size: var(--button-medium-font-size);
  --toggle-button-large-line-height: var(--button-medium-line-height);
  --toggle-button-large-font-weight: var(--button-medium-font-weight);
  --toggle-button-large-letter-spacing: var(--button-medium-letter-spacing);
  --toggle-button-small-font-size: var(--button-small-font-size);
  --toggle-button-small-line-height: var(--button-small-line-height);
  --toggle-button-small-font-weight: var(--button-small-font-weight);
  --toggle-button-small-letter-spacing: 0;
  --tooltip-background-color: var(--ui-03);
  --tooltip-text-color: var(--text-01);
  --tooltip-border-radius: 4px;
  --tooltip-font-size: var(--paragraph-04-font-size);
  --tooltip-line-height: var(--paragraph-04-line-height);
  --tooltip-font-weight: 400;
  --tooltip-letter-spacing: var(--paragraph-04-letter-spacing);
  --swipebar-background-color: var(--ui-04);
  --swipebar-color: var(--interactive-primary);
  --swipebar-padding: 32px 0 0;
  --sidebar-background-color: #F8F8F8;
  --sidebar-handle-color: var(--ui-04);
  --sidebar-footer-button-color: var(--text-01);
  --sidepanel-background-color: var(--ui-02);
  --sidepanel-close-icon-color: var(--text-01);
  --sidepanel-floating-shadow: var(--shadow-large);
  --sidepanel-light-border-color: var(--ui-04);
  --sidepanel-light-background-color: var(--ui-03);
  --sidepanel-header-font-size: var(--heading-05-font-size);
  --sidepanel-header-line-height: var(--heading-05-line-height);
  --sidepanel-header-font-weight: var(--heading-05-font-weight);
  --sidepanel-header-letter-spacing: 0;
  --file-uploader-file-row-text-color: var(--text-01);
  --file-uploader-file-row-border-bottom-color: var(--ui-04);
  --file-uploader-file-row-hover-background-color: var(--ui-02);
  --file-uploader-file-row-icon-color: var(--success);
  --file-uploader-file-row-uploading-file-name-color: #9a9a9a;
  --file-uploader-file-name-font-size: var(--paragraph-03-font-size);
  --file-uploader-file-name-line-height: 16px;
  --file-uploader-file-name-font-weight: 600;
  --file-uploader-file-name-letter-spacing: 0;
  --file-uploader-hint-font-size: var(--paragraph-04-font-size);
  --file-uploader-hint-line-height: var(--paragraph-05-line-height);
  --file-uploader-hint-font-weight: 300;
  --file-uploader-hint-letter-spacing: 0;
  --file-uploader-drop-zone-border-color: var(--ui-06);
  --file-uploader-drop-zone-text-color: var(--text-01);
  --toolbar-border-bottom-color: var(--ui-04);
  --toolbar-background: var(--ui-02);
  --timefield-color: var(--text-01);
  --timefield-active-color: var(--interactive-primary);
  --timefield-font-size: var(--heading-06-font-size);
  --timefield-line-height: var(--heading-06-line-height);
  --timefield-font-weight: 600;
  --timefield-letter-spacing: var(--heading-06-letter-spacing);
  --timefield-error-color: var(--danger);
  --timefield-disabled-color: var(--disabled-01);
  --timefield-label-font-size: var(--paragraph-05-font-size);
  --timefield-label-line-height: var(--paragraph-05-line-height);
  --timefield-label-letter-spacing: var(--paragraph-05-letter-spacing);
  --timefield-label-font-weight: var(--paragraph-05-font-weight);
  --licence-plate-prefix-background: #003399;
  --licence-plate-prefix-color: var(--negative);


  --table-cell-padding: 6px 8px 5px;
  --table-header-cell-padding: 10px 12px 9px;
}

body,
:host {
  background-color: var(--ui-background);
  color: #676A6CFF !important;
}

.nx-table-cell {
  padding: var(--table-cell-padding) !important;
}

.nx-header-cell {
  padding: var(--table-header-cell-padding) !important;
}

.nx-card {
  padding: 12px !important;
}

.nx-grid {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.nx-grid__row {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.nx-modal__container {
  padding: 24px !important;
}

.nx-modal__content {
  margin-left: -24px !important;
  margin-right: -24px !important;
  padding: 0 24px !important;
  scrollbar-width: thin;
  scrollbar-color: #969696;
}

.nx-modal__actions {
  margin-bottom: -14px !important;
  margin-left: -14px !important;
  margin-right: -14px !important;
}

/* Works on Chrome, Edge, and Safari */
.nx-modal__content::-webkit-scrollbar {
  width: 12px;
}

.nx-modal__content::-webkit-scrollbar-track {
  background: #969696;
}

.nx-modal__content::-webkit-scrollbar-thumb {
  background-color: var(--interactive-primary);
  border-radius: 20px;
  border: 3px solid var(--interactive-primary);
}

.nx-dropdown-results__option {
  padding: 4px !important;
  font-size: 14px !important;
}

.nx-dropdown-results__option-checkicon {
  margin-top: 2px !important;
}

.nx-switcher__toggle {
  height: 18px !important;
  width: 44px !important;
  min-width: 44px !important;
}

.nx-switcher__dot {
  height: 14px !important;
  width: 14px !important;
}

.nx-action__icon {
  font-weight: 600 !important;
}

@media only screen and (max-width: 780px) {
  nx-toolbar{
   padding:0  10px !important;
  }
}

.swal2-styled.swal2-confirm {
  background: var(--interactive-primary) !important;
}

.swal2-styled.swal2-confirm:hover {
  background: var(--hover-primary) !important;
}

button.nx-action.is-selected .nx-action-icon__container, button.nx-action:hover .nx-action-icon__container {
  color: var(--interactive-primary);
}

.nx-radio-toggle__label-container {
  border-radius: 4px !important;
}

.nx-radio-toggle {
  background: var(--toggle-button-unselected-background-color);
  border-radius: 4px !important;
}

nx-datepicker-toggle button nx-icon svg {
  vertical-align: inherit;
}

header.nx-header {
  border-bottom: none !important;
}

nx-formfield[appearance="outline"] .nx-formfield__label-holder {
  padding: 5px;
}

nx-formfield[appearance="outline"] .nx-formfield__label {
  position: static !important;
}

.nx-radio-toggle--small .nx-radio-toggle__label-container {
  padding: 8px 12px !important;
}
